import 'lazysizes'
import mitt from 'mitt'

window.emitter = mitt()

/**
 * GLOBAL HELPERS
 */
import ScriptComponentLoader from './helpers/ScriptComponentLoader'
import SmoothScroll from './helpers/SmoothScroll'
import BarbaBodyClass from './helpers/BarbaBodyClass'
import CreateBarba from './helpers/CreateBarba'
import PageTransition from './helpers/PageTransitions'
import { Utils } from './helpers/Utils'

/**
 *  GLOBAL COMPONENTS
 */
import Preloader from './components/c-Preloader'
import NavigationBar from './components/c-NavigationBar'
import PropertyItem from './components/c-PropertyItem'
import FullscreenMedia from './components/c-FullscreenMedia'
import Slider from './components/c-Slider'
import GallerySlider from './components/c-GallerySlider'
import Video from './components/c-Video'
import PageTitle from './components/c-PageTitle'
import LargeParagraph from './components/c-LargeParagraph'
import FlexibleMedia from './components/c-FlexibleMedia'
import StickyNav from './components/c-StickyNav'
import MediaModal from './components/c-MediaModal'
import SingleMedia from './components/c-SingleMedia'
import TwoColMedia from './components/c-TwoColMedia'
import StickyAnchorNav from './components/c-StickyAnchorNav'
import ConversationalForm from './components/c-ConversationalForm'
import SingleHeroBlock from './components/c-SingleHeroBlock'
import Menu from './components/c-Menu'
import Testimonial from './components/c-Testimonial'
import TwoColCTA from './components/c-TwoColCTA'
import ArchiveCTA from './components/c-ArchiveCTA'
import CTA from './components/c-CTA'
import GalleryScroller from './components/c-GalleryScroller'
import ContactBlock from './components/c-ContactBlock'
import ModalGallery from './components/c-ModalGallery'
import Accordion from './components/c-Accordion'
import Map from './components/c-Map'


// string must appear as it does in the PHP class file
const componentMap = {
	'preloader': Preloader,
	'navigationBar': NavigationBar,
	'propertyItem': PropertyItem,
	'fullscreenMedia': FullscreenMedia,
	'slider': Slider,
	'video': Video,
	'pageTitle': PageTitle,
	'largeParagraph': LargeParagraph,
	'gallerySlider': GallerySlider,
	'flexibleMedia': FlexibleMedia,
	'stickyNav': StickyNav,
	'stickyAnchorNav': StickyAnchorNav,
	'mediaModal': MediaModal,
	'singleMedia': SingleMedia,
	'singleHeroBlock': SingleHeroBlock,
	'menu': Menu,
	'twoColMedia': TwoColMedia,
	'testimonial': Testimonial,
	'twoColCTA': TwoColCTA,
	'archiveCTA': ArchiveCTA,
	'cta': CTA,
	'galleryScroller': GalleryScroller,
	'contactBlock': ContactBlock,
	'modalGallery': ModalGallery,
	'accordion': Accordion,
	'map': Map
}


export default class BaseTheme {
	constructor() {

		this.scriptRefs 			= []

		window.isTouchscreen 		= Utils.isTouchDevice()
		this.scriptComponentLoader 	= new ScriptComponentLoader()

		if(!window.isTouchscreen) {
			window.smoothScroll 	= new SmoothScroll()
		}

		this.bobyClassHelper		= new BarbaBodyClass()
		this.barbaRef 				= new CreateBarba().barba
		this.pageTransition 		= new PageTransition({ element: document.querySelector('.c-PageTransition') })
		this.conversationForm 		= new ConversationalForm(document.querySelector('.c-ConversationalForm'))

		this.init()
		this.createBarba()
	}

	init() {

		this.scriptRefs = [] // Clear existing scriptRefs, if any

		this.scriptComponentLoader.getAndLoad(this.scriptRefs, componentMap)
		this.conversationForm.init()
	}

	initOnNewPage(data) {

		return new Promise((resolve) => {

			this.scriptRefs = [] // Clear existing scriptRefs

			this.scriptComponentLoader.filterAndLoad(this.scriptRefs, componentMap, data)

			resolve()
		})
	}

	createBarba() {

		this.barbaRef.init({
			schema: {
				prefix: 'data-transition',
			},
			// debug: true,
			transitions: [
				/** DEFAULT TRANSITION */
				{
					name: 'default-transition',
					once: (data) => {
	
						this.pageTransition.once()

					},
					leave: async (data) => {

						await new Promise(resolve => {
							this.pageTransition.leave(resolve)
						})

						if(data.current.namespace == 'page-appraisal') {
					
							// this.conversationForm.refreshButtons()
						}
					},
					beforeEnter: async (data) => {

						await new Promise(resolve => {
		
							this.scriptComponentLoader.unmountComponents(this.scriptRefs, data)

							resolve()
						})
			
						this.bobyClassHelper.getClasses(data)
					},
					after: async (data) => {

						await this.initOnNewPage(data)

						this.pageTransition.after()
			
					}
				},
				/** PROPERTY-ITEM IMAGE TRANSITION INTO NEXT SINGLE TEMPLATE */
				{
					name: 'project-transition',
					from: {

						// route will look for the links route page.
						// meaning all links that come from rentals/sales, 
						// to the single template, will trigger these transitions. 
						// i.e - slider items, featured items etc
						// could be useful later.

						// route: [
						// 	'rentals',
						// 	'sales'
						// ]

						namespace: [
							'page-lettings',
							'page-sales'
						]
					},
					to: {

						namespace: [
							'single'
						]
					},
					once: (data) => {
	
						this.pageTransition.once()

					},
					beforeLeave: async (data) => {

						await new Promise(resolve => {
							this.pageTransition.propertyItemBeforeLeave(resolve)
						})
						
					},
					leave: async (data) => {

						await new Promise(resolve => {
							this.pageTransition.propertyItemLeave(resolve)
						})
						
					},
					beforeEnter: async (data) => {

						await new Promise(resolve => {
		
							this.scriptComponentLoader.unmountComponents(this.scriptRefs, data)

							resolve()
						})
			
						this.bobyClassHelper.getClasses(data)
					},
					after: async (data) => {

						await this.initOnNewPage(data)

						await new Promise(resolve => {
							this.pageTransition.propertyItemAfter(resolve)
						})
					}
				},
				/** MENU TRANSITION */
				{
					name: 'menu-transition',
					from: {

						custom: ({ trigger }) => {
							return trigger.classList && trigger.classList.contains('menu-transition')
						}
					},
					beforeLeave: async (data) => {

						await new Promise(resolve => {
							this.pageTransition.menuTransitionBeforeLeave(resolve)
						})
					},
					leave: async (data) => {

						await new Promise(resolve => {
							this.pageTransition.menuTransitionLeave(resolve)
						})

						if(data.current.namespace == 'page-appraisal') {
					
							// this.conversationForm.refreshButtons()
						}
						
					},
					beforeEnter: async (data) => {

						await new Promise(resolve => {
		
							this.scriptComponentLoader.unmountComponents(this.scriptRefs, data)

							resolve()
						})
			
						this.bobyClassHelper.getClasses(data)
					},
					after: async (data) => {

						await this.initOnNewPage(data)

						this.pageTransition.menuTransitionAfter()
					}
				},
			]
		})
	}

	resize() {}

	update() {}
}


document.addEventListener('DOMContentLoaded', () => {
	new BaseTheme()
})
