
export default class BarbaBodyClass {
	constructor() {

	}

	getClasses(data) {
		if (data.current.container) {
			let nextHtml = data.next.html
			let parser = new DOMParser()
			let htmlDoc = parser.parseFromString(nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml), 'text/html')
			let bodyClasses = htmlDoc.querySelector('notbody').getAttribute('class')

			document.body.className = '' // Clear existing classes
			document.body.classList.add(...bodyClasses.split(' '))
		}
	}
}
  