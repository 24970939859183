import { Animations } from "../helpers/Animations"
import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class Accordion {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.buttonToggle 	= this.element.querySelector('.c-Accordion__toggle')
		this.content		= this.element.querySelector('.c-Accordion__content')

		this.isExpanded 	= false

		this.handleClick = (e) => { 
			this.isExpanded  = !this.isExpanded
			this.stateChangeHandler()
		}
	}

	init() {

		this.addEvents()
	}

	stateChangeHandler() {
		if(this.isExpanded === true) {
			this.open()
		} else {
			this.close()
		}
	}

	open() {
		GSAP.to(this.content, {
			height: 'auto',
			duration: Animations.timing.accordian,
			ease: Animations.ease.circInOut,
			onComplete: () => {
				ScrollTrigger.refresh()
			}
		})

		this.buttonToggle.classList.add('active')
	}

	close() {
		GSAP.to(this.content, {
			height: 0,
			duration: Animations.timing.accordian,
			ease: Animations.ease.circInOut,
			onComplete: () => {
				ScrollTrigger.refresh()
			}
		})

		this.buttonToggle.classList.remove('active')
	}

	addEvents() {
		this.buttonToggle.addEventListener('click', this.handleClick)
	}

	removeEvents() {
		this.buttonToggle.removeEventListener('click', this.handleClick)
	}

	unmount() {

		this.removeEvents()
	}
}
