import GSAP from 'gsap'

import { Utils } from '../helpers/Utils'

export default class ConversationalForm {
	constructor(el) {

		this.element 		= el

		// this.formsWrapper 	= this.element.querySelector('.c-ConversationalForm__forms')

		// this.sellForm 		= this.element.querySelector('.c-ConversationalForm__forms__sell')
		// this.letForm 		= this.element.querySelector('.c-ConversationalForm__forms__let')

		// this.sellButton 	= this.element.querySelector('.sell-btn')
		// this.letButton 		= this.element.querySelector('.let-btn')

		// this.handleSellClick 	= this.handleSellClick.bind(this)
		// this.handleLetClick 	= this.handleLetClick.bind(this)
	}

	init() {

		// this.sellButton.addEventListener('click', this.handleSellClick)
		// this.letButton.addEventListener('click', this.handleLetClick)

		if(window.isTouchscreen) {
			Utils.setElementViewportHeight()
		}
	}

	handleSellClick() {

		GSAP.to([this.sellButton, this.letButton], {
			autoAlpha: 0,
			onComplete: () => {

				GSAP.set([this.sellButton, this.letButton], {
					display: 'none'
				})

				GSAP.set([this.formsWrapper, this.sellForm], {
					display: 'block'
				})
			}
		})
	}

	handleLetClick() {

		GSAP.to([this.sellButton, this.letButton], {
			autoAlpha: 0,
			onComplete: () => {

				GSAP.set([this.sellButton, this.letButton], {
					display: 'none'
				})

				GSAP.set([this.formsWrapper, this.letForm], {
					display: 'block'
				})
			}
		})
	}

	refreshButtons() {

		GSAP.set([this.sellButton, this.letButton], {
			display: 'block',
			autoAlpha: 1
		})

		GSAP.set([this.formsWrapper, this.letForm, this.sellForm], {
			display: 'none'
		})
	}

	unmount() {

	}
}
