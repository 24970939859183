import Flickity from 'flickity'
import GSAP from 'gsap'

export default class Slider {
	constructor(el, index) {
		this.element 	= el
		this.index 		= index

		this.wrapper 	= this.element.querySelector('.c-Slider__wrapper')
		this.titles 	= [...this.element.querySelectorAll('.c-Slider__titles a')]

		this.removePointerEvents 	= this.removePointerEvents.bind(this)
		this.addPointerEvents 		= this.addPointerEvents.bind(this)
		this.animateTitles			= this.animateTitles.bind(this)
	}

	init() {

		this.flkty = new Flickity(this.wrapper, {
			wrapAround: true,
			pageDots: false,
			initialIndex: 0,
			prevNextButtons: true,
			resize: true,
			draggable: true,
			autoPlay: true,
			adaptiveHeight: false,
			percentPosition: true,
			pauseAutoPlayOnHover: false,
			lazyLoad: 2,
			selectedAttraction: 0.08,
			friction: 0.95
		})

		this.bindEvent()
		this.setupTitles()
	}

	setupTitles() {

		GSAP.set(this.titles, {
			yPercent: i => (-this.flkty.selectedIndex * 100) + i * 100,
		})
	}

	animateTitles() {

		GSAP.to(this.titles, {
			duration: 1,
			ease: 'expo.out',
			overwrite: "auto",
			yPercent: i => (-this.flkty.selectedIndex * 100) + i * 100,
		})
	}

	removePointerEvents() {
		this.flkty.slider.style.pointerEvents = 'none'
	}

	addPointerEvents() {
		this.flkty.slider.style.pointerEvents = 'auto'
	}

	bindEvent() {
		this.flkty.on('dragStart', this.removePointerEvents)
		this.flkty.on('dragEnd', this.addPointerEvents)
		// this.flkty.on('dragEnd', this.animateTitles)
		this.flkty.on('change', this.animateTitles)
	}

	destroy() {
		this.flkty.off('dragStart', this.removePointerEvents)
		this.flkty.off('dragEnd', this.addPointerEvents)
		// this.flkty.off('dragEnd', this.animateTitles)
		this.flkty.off('change', this.animateTitles)

		this.flkty.destroy()
	}

	unmount() {
		if(this.flkty) this.destroy()
	}
}