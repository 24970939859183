import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class TwoColMedia {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.masks 			= [...this.element.querySelectorAll('.c-FlexibleMedia__media__mask')]
		this.media 			= [...this.element.querySelectorAll('img')]
	}

	init() {

		this.masks.forEach((el, idx) => {
			el.style.setProperty('--index', idx)
		})

		this.media.forEach((el, idx) => {
			el.style.setProperty('--index', idx)
		})

		this.scrollTriggerInstance = ScrollTrigger.create({
			trigger: this.element ,
				start: () => `top bottom-=${this.masks[1].offsetHeight * 0.5}`,
				once: true,
				onEnter: () => {
					this.element.classList.add('in-view')
				}
		})
	}

	unmount() {
		if(this.scrollTriggerInstance) this.scrollTriggerInstance.kill()
	}
}
