import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class StickyAnchorNav {
	constructor(el, index) {

		this.element 			= el
		this.index 				= index

		this.links 				= [...this.element.querySelectorAll('a')]
		this.isMobile 			= window.isTouchscreen

		if(!this.isMobile) {
			this.clickHandler = this.lenisScrollToLink.bind(this)
		}

		if(this.isMobile) {
			this.clickHandler = this.handleTouchscreenClick.bind(this)
		}
	}

	init() {
		this.toggleVisibility()
		this.addClickEventListeners()
	}

	addClickEventListeners() {
		this.links.forEach(link => {
			link.addEventListener('click', this.clickHandler)
		})
	}

	removeClickEventListeners() {
		this.links.forEach(link => {
			link.removeEventListener('click', this.clickHandler)
		})
	}

	lenisScrollToLink(e) {
		window.smoothScroll.lenis.scrollTo(`#${e.target.dataset.id}`, {
			offset: -100
		})
	}

	handleTouchscreenClick(e) {
		e.preventDefault()

		const target = document.getElementById(`${e.target.dataset.id}`)

		target.scrollIntoView({
			behavior: 'smooth'
		})
	}

	toggleVisibility() {

		this.ST = ScrollTrigger.create({
			trigger: 'main',
			start: 'top top',
			// endTrigger: 'main',
			end: 'bottom bottom',
			onEnterBack: () => {
				//console.log('enter back')
				this.show()
			},
			onLeave: () => {
				//console.log('leave')
				this.hide()
			}
		})
	}

	show() {
		GSAP.to(this.element, {
			y: 0
		})
	}

	hide() {
		GSAP.to(this.element, {
			y: '100%'
		})
	}

	destroy() {
		if(this.ST) this.ST.kill()

		if(!this.isMobile) this.removeClickEventListeners() 
	}

	unmount() {
		
		this.destroy()
	}
}
