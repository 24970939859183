import HLS from 'hls.js'
import GSAP from 'gsap'

export default class Video {
	constructor(el, index) {
		this.element 			= el
		this.index 				= index

		this.video 				= this.element.querySelector('video')
		this.videoSource 		= this.video.querySelector('source')

		this.loadVideo 			= this.loadVideo.bind(this)
		this.mmDesktop 			= GSAP.matchMedia()
		this.mmMobile 			= GSAP.matchMedia()

		this.hasLoaded 			= false
		this.intObserver		= null
		this.hasEnteredViewport = false
		this.canObserve 		= false

		this.dataOptions		= this.element.getAttribute('data-options')
		this.parsedData 		= JSON.parse(this.dataOptions)

		this.handleIntersection = this.handleIntersection.bind(this)
	}

	init() {

		this.setupObserver()

		this.hls = new HLS({
			capLevelToPlayerSize: true,
			autoStartLoad: true,
			startLevel: 4
		})

		if(this.parsedData.videoType == 'desktop') {

			this.mmDesktop.add("(min-width: 1025px)", () => {

				this.canObserve = true

				if(!this.hasLoaded) {
					if(HLS.isSupported()) {
						this.loadHLS()
 					} else {
						this.loadBackup()
					}
				} 

				if(this.hasLoaded && this.video.paused) {
					this.video.play()
				}

				GSAP.set(this.element, {
					display: 'grid'
				})
		
				return () => {
					// custom cleanup code here (runs when it STOPS matching)
					this.video.pause()

					this.canObserve = false

					GSAP.set(this.element, {
						display: 'none'
					})
				}
			})
		} else if(this.parsedData.videoType == 'mobile') {

			this.mmMobile.add("(max-width: 1024px)", () => {

				this.canObserve = true

				if(!this.hasLoaded) {
					if(HLS.isSupported()) {
						this.loadHLS()
 					} else {
						this.loadBackup()
					}
				} 

				if(this.hasLoaded && this.video.paused) {
					this.video.play()
				}

				GSAP.set(this.element, {
					display: 'grid'
				})
		
				return () => {
					// custom cleanup code here (runs when it STOPS matching)
					this.canObserve = false
					this.video.pause()
					GSAP.set(this.element, {
						display: 'none'
					})
				}
			})
		}
	}

	loadBackup() {
		const videoSrc = this.parsedData.backupSRC

		if (!videoSrc) {
			return
		}

		//console.log('🎥 Using Backup')

		this.videoSource.type = 'video/mp4'
		this.video.src 	= videoSrc
		this.isLoaded 	= true

		this.element.classList.add('loaded')
		this.video.load()
		this.video.play()
	}

	loadHLS() {
		
		this.hls.loadSource(this.videoSource.getAttribute('data-src'))
		this.hls.attachMedia(this.video)

		this.hls.on(HLS.Events.MEDIA_ATTACHED, this.loadVideo)
	}

	loadVideo() {
		//console.log('🎥 Using HLS.js')

		this.video.play()

		this.element.classList.add('loaded')
		this.hasLoaded = true
	}

	playVideo() {
		this.video.play()
	}

	setupObserver() {

		this.intObserver = new IntersectionObserver(this.handleIntersection, {
			root: null,
			rootMargin: '0px',
			threshold: 0,
		})

		this.intObserver.observe(this.video)
	}

	handleIntersection(entries) {
		entries.forEach(entry => {

			if(this.canObserve) {

				if (entry.isIntersecting) {
	
					this.hasEnteredViewport = true

					if(this.video.paused) {
						this.video.play()
					}
	
				} else if(this.hasEnteredViewport && !entry.isIntersecting) {

					this.video.pause()
				}
			}
		})
	}

	disconnect() {
		// Stop observing and disconnect the Intersection Observer
		if (this.intObserver) {
			this.intObserver.unobserve(this.video)
			this.intObserver.disconnect()
		}
	}

	destroyHLS() {
		this.hls.off(HLS.Events.MEDIA_ATTACHED, this.playVideo)
		this.hls.destroy()
	}

	unmount() {

		this.disconnect()

		this.mmDesktop 	= null
		this.mmMobile 	= null
		
		if(HLS.isSupported()) {
			this.destroyHLS()
		}

		this.hasLoaded 			= false
		this.intObserver		= null
		this.hasEnteredViewport = false
		this.canObserve 		= false
	}
}
