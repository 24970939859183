
export default class ContactBlock {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.columns 		= [...this.element.querySelectorAll('.c-ContactBlock__column')]
		this.show 			= this.show.bind(this)
	}

	init() {

		this.setIndexes(this.columns)
		window.emitter.on('pageReady', this.show)
	}

	show() {
		this.element.classList.add('in-view')
	}

	setIndexes(elements) {

		elements.forEach((el, idx)=> {
			el.style.setProperty('--index', idx)
		})
	}

	unmount() {

		window.emitter.off('pageReady', this.show)
	}
}
