const Animations = {

	ease: {
		spring: "elastic.out(1, 0.2)",
		expoInOut: "expo.inOut",
		generic: "expo.out",
		circInOut: "circ.inOut",
		circOut: "circ.out",
		sin: "sin.inOut",
		powerTwo: "power2.inOut",
		powerFour: "power4.inOut"
	},

	timing: {
		generic: 1,
		accordian: 0.6,
		modals: 0.8,
		lgTitle: 3,
		mdTitle: 1,
		pageOut: 1,
		pageIn: 1.4
	},

	stagger: {
		lgTitle: 0.5,
		mdTitle: 0.15
	}
}

export { Animations }