import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { Animations } from '../helpers/Animations'

GSAP.registerPlugin(ScrollTrigger, ScrollTrigger)

export default class FullscreenMedia {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.media 			= this.element.querySelector('.c-FullscreenMedia__media')
		this.title 			= this.element.querySelector('h1')

		this.animateTitle 	= this.animateTitle.bind(this)
		this.tlInstance 	= GSAP.timeline()

		this.vh 			= null
	}

	init() {

		this.setupScrolltrigger()
		this.setupTitleAnimation()

		window.emitter.on('pageReady', this.animateTitle)

		if(window.isTouchscreen) {
			this.setElementHeight()
		}
	}

	setupScrolltrigger() {

		this.masterTimeline = GSAP.timeline({
			scrollTrigger: {
				trigger: this.element,
				start: 'top top',
				end: 'bottom top',
				scrub: true,
			}
		})

		this.masterTimeline.to(this.media, {
			yPercent: 40,
			ease: 'none'
		})
	}

	setupTitleAnimation() {

		this.split = new SplitText(this.title, {
			type: 'words',
			wordsClass: "parent",
		})

		GSAP.set(this.split.words, {
			opacity: 0,
			yPercent: 60,
		})
	}

	animateTitle() {

		this.tlInstance.to(this.split.words, {
			ease: Animations.ease.generic,
			opacity: 1,
			duration: Animations.timing.lgTitle,
			yPercent: 0,
			stagger: {
				amount: Animations.stagger.lgTitle
			}
		})
	}

	setElementHeight() {
		this.vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${this.vh}px`)
	}

	unmount() {
		if(this.masterTimeline) this.masterTimeline.kill()
		if(this.split) this.split.revert()

		window.emitter.off('pageReady', this.animateTitle)
	}
}
