import GSAP from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class LargeParagraph {
	constructor(el, index) {

		GSAP.registerPlugin(ScrollTrigger, SplitText)

		this.element 	= el
		this.index 		= index

		this.paragraph 	= this.element.querySelector('p')
	}
  
	init() {
		this.setup()
		this.setupScrolltrigger()
	}
  
	setup() {

		this.split = new SplitText(this.paragraph, {
			type: 'words',
			wordsClass: "parent",
		})
	}

	setupScrolltrigger() {

		this.masterTimeline = GSAP.timeline({
			scrollTrigger: {
				trigger: this.element,
				start: () => `top center-=${this.paragraph.offsetHeight * 0.5}`,
				// end: 'bottom center',
				end: `+=${window.innerHeight * 1}`,
				pin: true,
				scrub: true,
			}
		})

		this.masterTimeline.to(this.split.words, {
			stagger: 1,
			className: 'fx'
		})

	}

	unmount() {
		if(this.split) this.split.revert()
		if(this.masterTimeline) this.masterTimeline.kill()
	}
}
