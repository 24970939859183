import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Utils } from '../helpers/Utils'

GSAP.registerPlugin(ScrollTrigger)

export default class FlexibleMedia {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.zIndex 		= 0
		this.currentIndex 	= 0

		this.medias 		= [...this.element.querySelectorAll('.c-FlexibleMedia__media__wrapper')]
		this.positioners 	= [...this.element.querySelectorAll('figure')]

		this.mm 			= GSAP.matchMedia()

		this.updateHandler 		= this.update.bind(this)

		this.handleMouseMove 	= this.handleMouseMove.bind(this)
		this.handleMouseLeave	= this.handleMouseLeave.bind(this)
		this.handleMouseClick	= this.handleMouseClick.bind(this)

		this.handleResize 		= Utils.debounceResize(this.resize.bind(this), 300)
	}

	init() {

		this.mm.add("(min-width: 1025px)", () => {

			// console.log('desktop')
			this.setScrollTrigger()
	
			return () => {
			  	// custom cleanup code here (runs when it STOPS matching)
			  	// console.log('cleanup')
			  	this.destroy()
	
			  	GSAP.set(this.medias, {
					clearProps:"all"
				})
			}
		})
		  
	}

	addEvents() {

		this.element.addEventListener('mousemove', this.handleMouseMove)
		this.element.addEventListener('mouseleave', this.handleMouseLeave)
		this.element.addEventListener('click', this.handleMouseClick)

		window.addEventListener('resize', this.handleResize)
	}

	setScrollTrigger() {

		setTimeout(() => {
			this.resize()
		}, 50)

		this.ST = ScrollTrigger.create({
			trigger: this.element,
			start: 'top bottom',
			end: 'bottom top',
			onEnter: () => {
				GSAP.ticker.add(this.updateHandler)
			},
			onEnterBack: () => {
				GSAP.ticker.add(this.updateHandler)
			},
			onLeave: () => {
				GSAP.ticker.remove(this.updateHandler)
			},
			onLeaveBack: () => {
				GSAP.ticker.remove(this.updateHandler)
			}
		})

		this.addEvents()
	}

	/**
	 * MOUSE EVENTS
	 */

	handleMouseMove(e) {

		if(!this.hasEntered) {
			this.element.classList.add('active')
		}

		this.hasEntered = true

		this.mouses.forEach(m => {
			m.x =  e.clientX - this.mediaWidth * 0.5
			m.y =  e.pageY - this.offset - this.mediaWidth * 0.5
		})

	}

	handleMouseLeave(e) {

		this.hasEntered = false

		this.element.classList.remove('active')

		this.mouses.forEach((m, idx)=> {
			m.x = this.positions[idx].x
			m.y = this.positions[idx].y
		})

		this.medias.forEach(m => {
			m.style.zIndex = 0
		})

		this.zIndex = 0
		this.currentIndex = 0
	}

	handleMouseClick() {

		this.zIndex += 1

		this.currentIndex % (this.medias.length) === 0 ? this.currentIndex = 0 : this.currentIndex

		this.medias[this.currentIndex].style.zIndex = this.zIndex

		this.currentIndex += 1
	}

	update() {

		if(!this.offset) return

		this.cursors.forEach((c, idx) => {
			
			c.x += (this.mouses[idx].x - c.x) * 0.07
			c.y += (this.mouses[idx].y - c.y) * 0.07
		})

		this.medias.forEach((m, idx) => {
			m.style.transform = `translate3d(${(this.cursors[idx].x)}px, ${(this.cursors[idx].y)}px, 0)`
		})
	}

	resize() {
		this.centerX 	= this.element.getBoundingClientRect().width / 2
		this.centerY 	= this.element.getBoundingClientRect().height / 2
		this.offset 	= this.element.getBoundingClientRect().top + window.smoothScroll.lenis.actualScroll

		this.mediaWidth = this.medias[0].getBoundingClientRect().width
		this.mediaHeight = this.medias[0].offsetHeight

		this.mouses = [
			{
				x: this.positioners[0].getBoundingClientRect().left,
				y: this.centerY - this.mediaHeight * 0.5
			},
			{
				x: this.centerX - this.mediaWidth * 0.5,
				y: this.centerY - this.mediaHeight * 0.5
			},
			{
				x: this.positioners[2].getBoundingClientRect().left,
				y: this.centerY - this.mediaHeight * 0.5
			},
		]

		this.cursors = [
			{
				x: 0,
				y: 0,
			},
			{
				x: 0,
				y: 0,
			},
			{
				x: 0,
				y: 0,
			},
		]

		this.positions = [
			{
				x: this.positioners[0].getBoundingClientRect().left,
				y: this.centerY - this.mediaHeight * 0.5
			},
			{
				x: this.centerX - this.mediaWidth * 0.5,
				y: this.centerY - this.mediaHeight * 0.5
			},
			{
				x: this.positioners[2].getBoundingClientRect().left,
				y: this.centerY - this.mediaHeight * 0.5
			},
		]
	}

	destroy() {
		if(this.ST) this.ST.kill()
		GSAP.ticker.remove(this.updateHandler)

		this.element.removeEventListener('mousemove', this.handleMouseMove)
		this.element.removeEventListener('mouseleave', this.handleMouseLeave)
		this.element.removeEventListener('click', this.handleMouseClick)

		window.removeEventListener('resize', this.handleResize)
	}

	unmount() {
		this.destroy()
	}
}
