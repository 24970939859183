import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'

export default class Menu {
	constructor(el, index) {
		this.element 			= el
		this.index 				= index

		this.menuToggler 		= this.element.querySelector('.c-Menu__toggler__btn')
		this.isExpanded			= false
		this.animatedElements 	= [...this.element.querySelectorAll('.c-Menu__toggler__btn span, nav a, .c-Menu__footer span')]
		this.mm 				= GSAP.matchMedia()

		this.stateChangeHandler = (e) => { 
			this.isExpanded  = !this.isExpanded
			this.stateChange()
		}

		window.menuObject.push(this)
	}
  
	init() {

		this.mm.add({
			isDesktop: `(min-width: 1024px)`,
			isMobile: `(max-width: 1023px)`
		}, (context) => {
			let { isDesktop, isMobile } = context.conditions

			return () => { 
				// clean up stuff that is run when breakpoint no
				// longer matches
				if(isMobile && this.isExpanded === true) {

					this.isExpanded = false

					GSAP.set(this.element, {
						x: '100%'
					})

					this.element.classList.remove('active-in')
				}
			}
		})

		this.setAnimatedElementsIndex()

		window.emitter.on('menuCalled', this.stateChangeHandler)
		this.menuToggler.addEventListener('click', this.stateChangeHandler)
	}

	stateChange() {
		if(this.isExpanded === true) {
			this.open()
		} else {
			this.close()
		}
	}

	setAnimatedElementsIndex() {
		this.animatedElements.forEach((el, idx) => {
			el.style.setProperty('--index', idx)
		})
	}

	open() {

		this.tl = GSAP.timeline()

		this.tl.set(this.element, {
			x: 0,
			autoAlpha: 0
		})
		this.tl.to(this.element, {
			autoAlpha: 1,
			onComplete: () => {
				this.element.classList.add('active-in')
			}
		})
	}

	close() {

		this.tlClose = GSAP.timeline()

		this.tlClose.to(this.element, {
			autoAlpha: 0,
			onComplete: () => {
				this.element.classList.remove('active-in')
			}
		})
		this.tlClose.set(this.element, {
			x: '100%'
		})
	}

	menuTransitionOut() {

		this.animatedElements.forEach(el => {
			el.style.transition = 'none'
		})

		GSAP.set(this.animatedElements, {
			y: 0,
		})

		// if(window.isTouchscreen) {
		// 	document.documentElement.style.scrollBehavior = 'auto'
		// }

		this.element.classList.remove('active-in')

		GSAP.to(this.animatedElements, {
			y: '-110%',
			stagger: 0.05,
			onComplete: () => {

				GSAP.set(this.animatedElements, {
					y: '110%'
				})

				window.scrollTo(0, 0)
	
				window.emitter.emit('menuOut')
			}
		})
	}

	menuTransitionAfter() {

		GSAP.to(this.element, {
			autoAlpha: 0,
			onComplete: () => {
				window.emitter.emit('pageReady')

				this.element.classList.remove('active-in')

				GSAP.set(this.element, {
					x: '100%'
				})

				// if(window.isTouchscreen) {
				// 	document.documentElement.style.scrollBehavior = 'smooth'
				// }

				this.animatedElements.forEach(el => {
					el.style.transform = ''
					el.style.transition = ''
				})

				this.isExpanded = false
			}
		})
	}

	unmount() {
		this.isExpanded 	= false
		window.emitter.off('menuCalled', this.stateChangeHandler)
		this.menuToggler.removeEventListener('click', this.stateChangeHandler)
	}
}
