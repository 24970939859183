import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class StickyNav {
	constructor(el, index) {

		this.element 			= el
		this.index 				= index
		this.modalToggle 		= this.element.querySelector('.c-StickyNav__modal-toggle')
		this.modalToggleWrapper = this.element.querySelector('.c-StickyNav__modal-toggle__wrapper')
		this.toggleCounter		= this.element.querySelector('.c-StickyNav__modal-toggle__counter')

		this.mapLink 			= this.element.querySelector('.c-StickyNav__map a')

		this.target 			= document.querySelector('.c-SingleBlock')
		this.endTarget 			= document.querySelector('.c-FeaturedProperties')

		this.isActive 			= false

		this.handleClick		= this.handleClick.bind(this)
		this.updatePhotoCounter = this.updatePhotoCounter.bind(this)
		this.show 				= this.show.bind(this)

		this.toggleVisibility 	= this.toggleVisibility.bind(this)

		if(!window.isTouchscreen) {
			this.clickHandler = this.lenisScrollToLink.bind(this)
		}

		if(window.isTouchscreen) {
			this.clickHandler = this.handleTouchscreenClick.bind(this)
		}
	}

	init() {

		this.addEvents()
	}


	lenisScrollToLink(e) {

		window.smoothScroll.lenis.scrollTo(`#${e.target.dataset.id}`, {
			offset: -100
		})
	}

	handleTouchscreenClick(e) {
		e.preventDefault()

		const target = document.getElementById(`${e.target.dataset.id}`)

		target.scrollIntoView({
			behavior: 'smooth'
		})
	}

	addEvents() {
		this.modalToggle.addEventListener('click', this.handleClick)
		this.mapLink.addEventListener('click', this.clickHandler)

		window.emitter.on('modalImageCount', this.updatePhotoCounter)
		window.emitter.on('pageReady', this.show)
		window.emitter.on('pageReady', this.toggleVisibility)
	}

	removeEvents() {
		this.modalToggle.removeEventListener('click', this.handleClick)
		this.mapLink.removeEventListener('click', this.clickHandler)

		window.emitter.off('modalImageCount', this.updatePhotoCounter)
		window.emitter.off('pageReady', this.show)
		window.emitter.off('pageReady', this.toggleVisibility)
	}

	updatePhotoCounter(data) {
		this.toggleCounter.textContent = `(${data.count})`
	}

	toggleVisibility() {

		this.ST = ScrollTrigger.create({
			trigger: '.c-FeaturedProperties',
			start: 'top bottom',
			onEnter: () => {
				//console.log('enter')
				this.hide()
			},
			onLeaveBack: () => {
				//console.log('leave back')
				this.show()
			},
		})

		ScrollTrigger.refresh()
	}

	show() {
		GSAP.to(this.element, {
			y: 0
		})
	}

	hide() {
		GSAP.to(this.element, {
			y: '100%'
		})
	}

	handleClick() {

		if(!this.isActive) {
			GSAP.to(this.modalToggleWrapper, {
				yPercent: -50
			})

			this.mapLink.classList.add('inactive')
		} else {
			GSAP.to(this.modalToggleWrapper, {
				yPercent: 0,
				onComplete: () => {
					this.mapLink.classList.remove('inactive')
				}
			})
		}

		this.isActive = !this.isActive

		window.emitter.emit('modalGalleryInteracted')
	}

	destroy() {
		if(this.ST) this.ST.kill()

		this.removeEvents()
	}

	unmount() {
		
		this.destroy()
	}
}
