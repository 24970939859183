import GSAP from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger, SplitText)

export default class ArchiveCTA {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.media 			= this.element.querySelector('figure')
		this.title 			= this.element.querySelector('h2')

		this.paragraphs 	= [...this.element.querySelectorAll('p')]
	}

	init() {

		this.setupSplitText()
		this.setupScrollTrigger()
	}

	setupSplitText() {

		this.splitParent = new SplitText([this.title, this.paragraphs], {
			type: 'words',
			wordsClass: "parent",
		})

		this.splitChild = new SplitText([this.title, this.paragraphs], {
			type: 'words',
			wordsClass: "child",
		})

		const elements = this.element.querySelectorAll('.c-ArchiveCTA__media__mask, img, .child')

		this.setIndexes(elements)
	}

	setIndexes(elements) {

		elements.forEach((el, idx)=> {
			el.style.setProperty('--index', idx)
		})
	}

	setupScrollTrigger() {

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: () => `top bottom-=${this.media.offsetHeight}`,
			once: true,
			onEnter: () => this.element.classList.add('in-view')
		})
	}

	unmount() {
		if(this.STInstance) this.STInstance.kill()
		this.splitParent.revert()
		this.splitChild.revert()
	}
}
