import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Animations }	from '../helpers/Animations'

import BodyScrollLock from '../helpers/BodyScrollLock'

export default class MediaModal {
	constructor(el, index) {

		this.element 			= el
		this.index 				= index

		this.toggleVisibility 	= this.toggleVisibility.bind(this)
		this.isActive 			= false
		this.STInstance 		= null
		this.images 			= [...this.element.querySelectorAll('.image')]
		this.wrapper			= this.element.querySelector('.c-MediaModal__wrapper')
		this.contentWrapper 	= this.element.querySelector('.c-MediaModal__wrapper__content')
		this.layouts 			= [...this.element.querySelectorAll('.c-MediaModal__layout')]
		this.spacer 			= this.element.querySelector('.c-MediaModal__spacer')
		this.spacerInner 		= this.element.querySelector('.c-MediaModal__spacer__inner')

		this.mm 				= GSAP.matchMedia()
	}

	init() {

		this.setupEvents()

		/**
		 * IF IT'S NOT TOUCH DO THIS
		 */
		if(!window.isTouchscreen) {

			this.mm.add({
				isDesktop: `(min-width: 1024px)`,
				isMobile: `(max-width: 1023px)`
			}, (context) => {
				let { isDesktop, isMobile } = context.conditions

				if(isDesktop) {
					this.setupSlider()
				}
	
				return () => { 
					// clean up stuff that is run when breakpoint no
					// longer matches
					if(isMobile) {
						if(this.STInstance) this.STInstance.kill()

						GSAP.set(this.contentWrapper, {
							clearProps:"all"
						})
					}
				}
			})
		}
	}

	setupEvents() {

		if(this.images.length > 0) {
			window.emitter.emit('modalImageCount', { count: this.images.length })
		}

		window.emitter.on('modalGalleryInteracted', this.toggleVisibility)
	}

	setupSlider() {
		GSAP.set(this.spacerInner, {
		// height: `${(this.layouts.length - 1) * 100 + 50}` + 'vw'
			height: `${(this.layouts.length - 1) * 100}` + 'vw'
		})

		this.STInstance = GSAP.to(this.contentWrapper, {
			ease: "none",
			x: () => -(this.contentWrapper.offsetWidth - window.innerWidth),
			scrollTrigger: {
				scroller: this.spacer,
				trigger: this.spacer,
				start: "top top",
				end: "max",
				scrub: 1.4,
				invalidateOnRefresh: true
			}
		})

		setTimeout(() => {
			ScrollTrigger.refresh()
		}, 800)
	}

	lenisStop() {
		if(!window.isTouchscreen) window.smoothScroll.lenis.stop()

		if(window.isTouchscreen) {
			BodyScrollLock.removeScrollbars()

			this.wrapper.scrollTop = 0
		}
	}

	lenisStart() {
		if(!window.isTouchscreen) window.smoothScroll.lenis.start()

		if(window.isTouchscreen) {
			BodyScrollLock.addScrollbars()
		}
	}

	toggleVisibility() {

		const tl = GSAP.timeline()

		if(!this.isActive) {

			this.lenisStop()

			tl.set(this.element, {
				x: '100%',
			})

			tl.to(this.element, {
				x: 0,
				duration: Animations.timing.modals,
				ease: Animations.ease.circOut,
			})
		} else {

			this.lenisStart()
	
			tl.to(this.element, {
				x: '100%',
				duration: Animations.timing.modals,
				ease: Animations.ease.circOut,
				onComplete: () => {
					this.spacer.scrollTop = 0
				}
			})
		}

		this.isActive = !this.isActive
	}

	unmount() {
		if(this.isActive) this.lenisStart()
		if(this.STInstance) this.STInstance.kill()

		window.emitter.off('modalGalleryInteracted', this.toggleVisibility)
	}
}
