import Flickity from 'flickity'

export default class GallerySlider {
	constructor(el, index) {
		this.element 	= el
		this.index 		= index

		this.wrapper 	= this.element.querySelector('.c-GallerySlider__wrapper')
	}

	init() {

		this.flkty = new Flickity(this.wrapper, {
			wrapAround: true,
			pageDots: false,
			groupCells: true,
			initialIndex: 0,
			prevNextButtons: false,
			resize: true,
			draggable: true,
			cellAlign: 'center',
			adaptiveHeight: false,
			// percentPosition: true,
			lazyLoad: 1
		})

	}

	destroy() {

		this.flkty.destroy()
	}

	unmount() {
		if(this.flkty) this.destroy()
	}
}