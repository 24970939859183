import GSAP from 'gsap'
import { Flip } from 'gsap/Flip'
import { Animations } from './Animations'
import { Utils } from './Utils'

GSAP.registerPlugin(Flip)

export default class PageTransition {
	constructor(config) {
		this.element 	= config.element
		this.stateOne 	= this.element.querySelector('.c-PageTransition__state-one__wrapper')
		this.stateTwo 	= this.element.querySelector('.c-PageTransition__state-two')
		this.mask 		= this.element.querySelector('.c-PageTransition__mask')

		window.currentPropertyLink 	= []
		window.menuObject			= []

		this.handleResize 	= Utils.debounceResize(this.resize.bind(this), 300)

		this.init()
	}

	init() {

		this.getScreenAspectRatio()

		window.addEventListener('resize', this.handleResize)
	}

	/**
	 * DEFAULT TRANSITIONS
	 */
	once() {

		document.body.style.cursor = 'wait'

		GSAP.set(this.element, {
			y: 0
		})

		GSAP.to(this.element, {
			delay: 1,
			autoAlpha: 0,
			duration: 1,
			onStart: () => {
				window.scrollTo(0, 0)
			},
			onComplete: () => {

				window.emitter.emit('pageReady')

				document.body.style.cursor = 'auto'

				GSAP.set(this.element, {
					y: '110%'
				})
			}
		})
	}

	leave(resolve) {
		const tl = GSAP.timeline()

		document.body.style.cursor = 'wait'

		if(window.isTouchscreen) {
			document.documentElement.style.scrollBehavior = 'auto'
		}

		tl.set(this.element, {
			backgroundColor: 'white',
			autoAlpha: 0
		})

		tl.set(this.element, {
			y: 0,
		})

		tl.to(this.element, {
			autoAlpha: 1,
			duration: 0.5,
			onComplete: () => { 
				resolve()
				window.scrollTo(0, 0)
			}
		})
	}

	after() {
		const tl = GSAP.timeline()

		tl.to(this.element, {
			delay: 0.5,
			autoAlpha: 0,
			duration: 0.5,
			onComplete: () => { 
				window.emitter.emit('pageReady')

				document.body.style.cursor = 'auto'

				GSAP.set(this.element, {
					y: '110%'
				})
			}
		})
	}

	/**
	 * DYNAMIC IMAGE TRANSITIONS
	 */
	propertyItemBeforeLeave(resolve) {
		const tl = GSAP.timeline()

		tl.set(this.element, {
			autoAlpha: 1,
			onComplete: () => {
				window.currentPropertyLink[0].resize()
			}
		})

		document.body.style.cursor = 'wait'

		this.element.style.backgroundColor = 'transparent'

		tl.set(this.element, {
			y: 0,
			onComplete: () => {
				window.currentPropertyLink[0].doFlip()
			}
		})

		tl.to(this.mask, {
			y: 0,
			duration: 1,
			ease: 'circ.inOut',
			onComplete: () => {
				resolve()
			}
		})
	}

	propertyItemLeave(resolve) {
		const image = this.element.querySelector('img')

		this.flipState = Flip.getState(image)

		this.stateTwo.appendChild(image)

		Flip.from(this.flipState, {
			delay: 0.5,
			duration: Animations.timing.pageIn,
			scale: window.innerWidth > 1024 ? true : false,
			ease: Animations.ease.expoInOut,
			absolute: true,
			simple: true,
			onComplete: () => { 
				window.scrollTo(0, 0)
				resolve()
			}
		})
	}

	propertyItemAfter(resolve) {

		const tl = GSAP.timeline()

		tl.set(this.element, {
			delay: 0.5,
			y: '110%',
			onComplete: () => {
				window.emitter.emit('pageReady')
				this.cleanupForPropertyItem()
				resolve()
			}
		})
	}

	getScreenAspectRatio() {

		if(window.innerWidth > 1024) {
			this.width = window.innerWidth
			this.height = window.innerHeight

			const aspectRatioPercentage = (this.height / this.width) * 100
			this.stateOne.style.paddingBottom = `${aspectRatioPercentage}%`
		} else {
			this.stateOne.style.paddingBottom = `${60}%`
		}
	}

	resize() {

		this.getScreenAspectRatio()
	}

	cleanupForPropertyItem() {
		document.body.style.cursor = 'auto'
		window.currentPropertyLink = []
		this.stateTwo.innerHTML = ''

		GSAP.set(this.mask, {
			y: '100%'
		})
	}

	/**
	 * MOBILE MENU TRANSITION
	 */
	menuTransitionBeforeLeave(resolve) {

		if(window.isTouchscreen) {
			//document.body.parentNode.style.scrollBehavior = 'auto'
		}

		resolve()
	}

	menuTransitionLeave(resolve) {

		window.menuObject[0].menuTransitionOut()

		window.emitter.on('menuOut', () => {
			resolve()
		})
	}

	menuTransitionAfter() {
		window.menuObject[0].menuTransitionAfter()
	}
}