import mapboxgl from 'mapbox-gl'

export default class Map {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.map 			= null
		this.mapContainer 	= this.element.querySelector('.c-Map__container')
		this.dataOptions	= this.element.getAttribute('data-options')
		this.parsedData 	= JSON.parse(this.dataOptions)

		this.accessToken 	= 'pk.eyJ1IjoiY29yZXJlc2lkZW50aWFsYWdlbmN5IiwiYSI6ImNsdmZldmNmZDBqYWsyanA1MXFjemdsencifQ.P3zIyLi575SsuziyLoKlow'

		this.intObserver		= null
		this.hasEnteredViewport = false
		this.canObserve 		= false
		this.handleIntersection = this.handleIntersection.bind(this)

		mapboxgl.accessToken = this.accessToken
	}

	init() {

		this.setupObserver()
	}

	initMap() {

		this.map = new mapboxgl.Map({
			container: this.mapContainer,
			style: 'mapbox://styles/mapbox/light-v11',
			zoom: 16,
			center: [parseFloat(this.parsedData.longitude), parseFloat(this.parsedData.latitude)]
		})

		this.alterControls()
		this.addMarker()
	}

	setupObserver() {

		this.intObserver = new IntersectionObserver(this.handleIntersection, {
			root: null,
			rootMargin: '0px',
			threshold: 0,
		})

		this.intObserver.observe(this.mapContainer)
	}

	handleIntersection(entries) {

		entries.forEach(entry => {

			if (entry.isIntersecting && !this.hasEnteredViewport) {
				this.hasEnteredViewport = true
				// console.log('map entered')
				this.initMap()

			} else if(this.hasEnteredViewport) {
				this.intObserver.unobserve(this.mapContainer)
			}
		})
	}

	alterControls() {
		this.map.scrollZoom.disable()
		this.map.addControl(new mapboxgl.NavigationControl())
	}

	addMarker() {
		const customMarker = new mapboxgl.Marker()
		.setLngLat([parseFloat(this.parsedData.longitude), parseFloat(this.parsedData.latitude)])
		.addTo(this.map)
	}

	disconnect() {

		if (this.intObserver) {
			this.intObserver.unobserve(this.mapContainer)
			this.intObserver.disconnect()
		}
	}

	unmount() {
		this.disconnect()
		if(this.map) this.map.remove()
	}
}
