import GSAP from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger, SplitText)

export default class CTA {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.title 				= this.element.querySelector('h2')
		this.paragraphs 		= this.element.querySelectorAll('p')

		this.dataOptions		= this.element.getAttribute('data-options')
		this.parsedData 		= JSON.parse(this.dataOptions)

		this.initOnPageReady 	= this.initOnPageReady.bind(this)
		this.revertSplit 		= this.revertSplit.bind(this)

		this.mediaElements 		= null
	}

	init() {

		if(this.parsedData.ctaType == 'standard') {
			this.setupStandardCTA()
		} else if(this.parsedData.ctaType == 'media') {
			this.setupMediaCTA()
		}

		window.emitter.on('pageReady', this.initOnPageReady)
	}

	initOnPageReady() {

		this.setupScrollTrigger()
	}

	setupStandardCTA() {

		this.splitParent = new SplitText([this.title, this.paragraphs], {
			type: 'words',
			wordsClass: "parent",
		})

		this.splitChild = new SplitText([this.title, this.paragraphs], {
			type: 'words',
			wordsClass: "child",
		})

		const elements = this.element.querySelectorAll('.child')

		this.setIndexes(elements)
		this.setupScrollTrigger()
	}

	setupMediaCTA() {

		this.splitParent = new SplitText([this.title, this.paragraphs], {
			type: 'words',
			wordsClass: "parent",
		})

		this.splitChild = new SplitText([this.title, this.paragraphs], {
			type: 'words',
			wordsClass: "child",
		})

		this.mediaElements = [...this.element.querySelectorAll('.c-CTA__media-mask, img, .child')]

		this.setIndexes(this.mediaElements)

		this.mediaElements[this.mediaElements.length - 1].addEventListener('transitionend', this.revertSplit)
	}

	revertSplit() {
		this.splitChild.revert()
		this.splitParent.revert()
	}

	setupScrollTrigger() {

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: () => `top bottom-=${window.innerHeight / 3}`,
			once: true,
			onEnter: () => this.element.classList.add('in-view')
		})
	}

	setIndexes(elements) {

		elements.forEach((el, idx)=> {
			el.style.setProperty('--index', idx)
		})
	}

	unmount() {
		this.splitParent.revert()
		this.splitChild.revert()

		if(this.STInstance) this.STInstance.kill()

		if(this.parsedData.ctaType == 'media') {
			this.mediaElements[this.mediaElements.length - 1].removeEventListener('transitionend', this.revertSplit)
		}

		window.emitter.off('pageReady', this.initOnPageReady)
	}
}
