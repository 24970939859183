import GSAP from 'gsap'
import { Flip } from 'gsap/Flip'
import { Utils } from '../helpers/Utils'

GSAP.registerPlugin(Flip)

export default class PropertyItem {
	constructor(el, index) {
		this.element 	= el
		this.index 		= index

		this.link 		=  this.element.querySelector('a')

		this.image 			= this.element.querySelector('img')
		this.transWrapper 	= document.querySelector('.c-PageTransition__state-one')

		this.handleClick 	= this.handleClick.bind(this)
		this.handleResize 	= Utils.debounceResize(this.resize.bind(this), 300)
	}

	init() {
		this.state = Flip.getState(this.image)

		this.element.addEventListener('click', this.handleClick)
		window.addEventListener('resize', this.handleResize)
	}

	handleClick(e) {

		window.currentPropertyLink.push(this)
	}

	doFlip() {

		this.transWrapper.appendChild(this.image)

		Flip.from(this.state, {
			duration: 1,
			ease: "expo.inOut",
			absolute: true,
			simple: true
		})
	}
	

	resize() {
		this.state = null

		this.state = Flip.getState(this.image)
	}

	unmount() {
		this.state = null
		Flip.killFlipsOf(this.image)
		window.currentPropertyLink = []
		window.removeEventListener('resize', this.handleResize)
	}
}