import GSAP from 'gsap'

export default class Preloader {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.logo 			= this.element.querySelector('.c-Preloader__logo')
		this.logoParts 		= [...this.element.querySelectorAll('svg path')]
		this.imagery 		= [...this.element.querySelectorAll('img')]
		this.imageryWrapper = this.element.querySelector('.c-Preloader__imagery')
		this.positioner 	= this.element.querySelector('.positioner')
	}

	init() {

		this.resize()


		const tl = GSAP.timeline()

		tl.set(this.imageryWrapper, {
			width: () => this.rectBounds.width,
			height: () => this.rectBounds.height,
		})

		tl.set(this.imageryWrapper, {
			x:() => (this.logo.offsetWidth * 0.5) - (this.imageryWrapper.offsetWidth * 0.5),
		})

		tl.set(this.imagery, {
			scale: 0,
			opacity: 1
		})

		tl.to(this.imagery, {
			delay: 0.3,
			duration: 1,
			ease: "expo.out",
			scale: 1,
			stagger: 0.2
		})

		tl.to(this.imageryWrapper, {
			delay: 0.1,
			duration: 1,
			x: () => '107%',
			ease: "expo.out",
		})

		tl.to(this.logoParts, {
			y: 0,
			ease: "expo.out",
			duration: 1.4,
			stagger: 0.2
		}, '<')

		tl.to(this.element, {
			clipPath: 'inset(0% 0% 100% 0%)',
			ease: "expo.out",
			duration: 1.4,
			onComplete: () => {
				GSAP.set(this.element, {
					yPercent: -100
				})
			}
		})
	}

	resize() {
		this.rectBounds = this.positioner.getBoundingClientRect()

		GSAP.set(this.imageryWrapper, {
			width: () => this.rectBounds.width,
			height: () => this.rectBounds.height,
			//x:() => (this.logo.offsetWidth * 0.5) - (this.imageryWrapper.offsetWidth * 0.5),
		})
	}

	unmount() {

	}
}
