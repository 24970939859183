/*
* Adds 'scroll-lock' class to the body which prevents scrolling

Also applies Jank fix, to prevent elements jumping when scrollbars are added/removed
By default this is only applied to the body element using a CSS variable,
The value of the CSS var is updated with JS when then fix needs applying.
but you may need to apply a similar rule to position:fixed elements...
width: calc(100% - var(--scrollbar-width));
works the best in my testing, but you could try margin/padding Eg...
padding-left: var(--scrollbar-width);

The added bonus of this technique is that if the user has scrollbars set to auto
the scrollbar-width is 0 and no jank fix is applied.

Users that have scrollbars set to 'always on' will have pages that are slightly
off-centre when 'scroll-lock' is applied. This is to accomodate the scroll bar
when it is shown again.

* CSS
In the very least you need:
body {
	width: calc(100% - var(--scrollbar-width));
}
body.scroll-lock {
	overflow: hidden;
}
*/
const BodyScrollLock = {
	
	// called before export at end of file, so init() is called when object is imported :)
	init() {

		// figure out the width of the scrollbar for use later
		this.scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
		
		// create css var of scrollbar width
		document.documentElement.style.setProperty('--scrollbar-width', this.scrollbarWidth + 'px')
	},

	removeScrollbars() {
		document.body.classList.add('scroll-lock');
		document.documentElement.style.setProperty('--scrollbar-width', this.scrollbarWidth + 'px')
	},

	addScrollbars() {
		document.body.classList.remove('scroll-lock')

		// this will only fire once. What if content changes and scrollbar appears?
		if (this.bodyScrollbarVisible()) {
			document.documentElement.style.setProperty('--scrollbar-width', '0px');
		}
	},

	// measures the height of the content in body and sees if it's more than the window height
	bodyScrollbarVisible() {
		return document.documentElement.scrollHeight > document.documentElement.clientHeight
	}

}
BodyScrollLock.init() // called on import

export default BodyScrollLock
