import GSAP from 'gsap'

import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Utils } from '../helpers/Utils'

GSAP.registerPlugin(ScrollTrigger)

export default class GalleryScroller {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.galleryWrapper = this.element.querySelector('.c-GalleryScroller__wrapper')
		this.media 			= [...this.element.querySelectorAll('figure')]
		this.images			= [...this.element.querySelectorAll('img')]

		this.tl 			= null
		this.STInstance 	= null
		this.galleryWidth 	= null

		this.handleResize 	= Utils.debounceResize(this.resize.bind(this), 300)
	}

	init() {

		this.loadImages()
		this.resize()
		this.setupTimelines()

		//window.addEventListener('resize', this.handleResize)
	}

	setupTimelines() {
		this.tl = GSAP.timeline()

		this.tl.to(this.galleryWrapper, {
			ease: 'none',
			// x: () => `-=${this.galleryWidth -( window.innerWidth - this.getOffsetFactor())}px`,
			x: () => `-=${this.galleryWrapper.getBoundingClientRect().width -( window.innerWidth - this.getOffsetFactor())}px`,
		})

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: () => `top center-=${this.galleryWrapper.offsetHeight * 0.5}`,
			pin: true,
			animation: this.tl,
			scrub: true,
			// end: () => `+=${this.galleryWidth -( window.innerWidth - this.getOffsetFactor())}`,
			end: () => `+=${this.galleryWrapper.getBoundingClientRect().width -( window.innerWidth)}`,
			invalidateOnRefresh: true
		})

		ScrollTrigger.refresh()
	}

	loadImages() {
		this.images.forEach(img => {
			const data = img.getAttribute('data-src')
			img.src = data
		})
	}

	resize() {
		// this.galleryWidth = 0

		// this.media.forEach(el => {
		// 	this.galleryWidth += el.offsetWidth
		// })

		// GSAP.set(this.galleryWrapper, {
		// 	width: `${this.galleryWidth}px`,
		// })
	}

	getOffsetFactor() {
		this.root = document.documentElement
		this.computedStyles = getComputedStyle(this.root)

		this.returnedValue = parseFloat(this.computedStyles.getPropertyValue('--offset-gallery-factor')) * 2

		return this.returnedValue
	}

	unmount() {
		if(this.STInstance) this.STInstance.kill()
		if(this.tl) this.tl.kill()

		window.removeEventListener('resize', this.handleResize)
	}
}
