import GSAP from 'gsap'

import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger, SplitText)

export default class Testimonial {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.STInstance 	= null

		this.paragraph 		= this.element.querySelectorAll('p')
	}

	init() {

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top bottom-=200px',
			once: true,
			onEnter: () => this.element.classList.add('in-view')
		})

		this.parents = new SplitText(this.paragraph, {
			type: 'words',
			wordsClass: "parent",
		})

		this.children = new SplitText(this.paragraph, {
			type: 'words',
			wordsClass: "child",
		})

		this.children.words.forEach((el, idx)=> {
			el.style.setProperty('--index', idx)
		})
	}

	unmount() {

		if(this.STInstance) this.STInstance.kill()
		this.parents.revert()
		this.children.revert()
	}
}
