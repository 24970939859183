import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'

export default class HeroBlock {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.titles 		= [...this.element.querySelectorAll('.child')]

		this.showTitle 			= this.showTitle.bind(this)
	}

	init() {

		window.emitter.on('pageReady', this.showTitle)

		if(window.isTouchscreen) {
			this.setElementHeight()
		}
	}

	setElementHeight() {
		this.vh = window.innerHeight * 0.01
		document.documentElement.style.setProperty('--vh', `${this.vh}px`)
	}

	showTitle() {
		GSAP.to(this.titles, {
			y: 0,
			ease: Animations.ease.circOut,
			duration: Animations.timing.mdTitle,
			stagger: Animations.stagger.mdTitle
		})
	}

	unmount() {

		window.emitter.off('pageReady', this.showTitle)
	}
}
