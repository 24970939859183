import GSAP from 'gsap'
import Flickity from 'flickity'
import { Animations }	from '../helpers/Animations'

export default class ModalGallery {
	constructor(el, index) {
		this.element 					= el
		this.index 						= index

		this.toggleVisibility 			= this.toggleVisibility.bind(this)
		this.isActive 					= false

		this.images 					= [...this.element.querySelectorAll('.image')]
		this.wrapper					= this.element.querySelector('.c-ModalGallery__wrapper')
		// this.cursor						= this.element.querySelector('.c-ModalGallery__cursor')
		this.progressBar 				= this.element.querySelector('.c-ModalGallery__progress')

		this.updateProgressBar 			= this.updateProgressBar.bind(this)

		if(!window.isTouchscreen) {
			// this.handleMouseMove 			= this.handleMouseMove.bind(this)
			// this.update 					= this.update.bind(this)
			// this.hideCursor 				= this.hideCursor.bind(this)
			// this.showCursor 				= this.showCursor.bind(this)
		}
	
		// this.cursorPosition 			= 0
		// this.cursorCurrentPosition 		= null
		// this.cursorPreviousPosition 	= null

		this.progress 					= 0

		// this.inertia = {
		// 	currentX: 0,
		// 	targetX: 0,
		// 	currentY: 0,
		// 	targetY: 0,
		// 	lerp: 0.1
		// }
	}

	init() {

		this.setupEvents()
		this.setupSlider()
	}

	// showCursor() {
	// 	GSAP.to(this.cursor, {
	// 		duration: 0.3,
	// 		autoAlpha: 1
	// 	})
	// }

	// hideCursor() {
	// 	GSAP.to(this.cursor, {
	// 		duration: 0.3,
	// 		autoAlpha: 0
	// 	})
	// }

	setupEvents() {

		if(this.images.length > 0) {
			window.emitter.emit('modalImageCount', { count: this.images.length })
		}

		// if(!window.isTouchscreen) {
		// 	this.element.addEventListener('mousemove', this.handleMouseMove)
		// 	this.element.addEventListener('mouseleave', this.hideCursor)
		// 	this.element.addEventListener('mouseenter', this.showCursor)
		// }
		window.emitter.on('modalGalleryInteracted', this.toggleVisibility)
	}

	setupSlider() {
		
		this.flkty = new Flickity(this.wrapper, {
			wrapAround: false,
			pageDots: false,
			initialIndex: 0,
			prevNextButtons: true,
			resize: true,
			draggable: window.isTouchscreen ? true : false,
			autoPlay: false,
			adaptiveHeight: false,
			selectedAttraction: 0.08,
			friction: 0.95,
			watchCSS: true
		})

		this.flkty.on('change', this.updateProgressBar)
	}

	goToSlide(idx) {
		/**
		 * flickity SELECT method takes 3 args
		 * index, isWrapped, isInstant
		 * setting isInstant to true, will go to
		 * the selcted slide without animation
		*/
		if(this.flkty) {
			this.flkty.select(idx, false, true)
		}
	}

	updateProgressBar() {

		this.progress = (this.flkty.selectedIndex / (this.flkty.slides.length - 1))

		GSAP.to(this.progressBar, {
			ease: 'power4.out',
			duration: 1.2,
			scaleX: this.progress
		})
	}

	toggleVisibility() {

		const tl = GSAP.timeline()

		if(!this.isActive) {

			tl.set(this.element, {
				x: '100%',
			})

			tl.to(this.element, {
				x: 0,
				duration: Animations.timing.modals,
				ease: Animations.ease.circOut,
				onComplete: () => {
					if(!window.isTouchscreen) {
						GSAP.ticker.add(this.update)
					}
				}
			})

		} else {

			if(!window.isTouchscreen) {
				GSAP.ticker.remove(this.update)
			}
	
			tl.to(this.element, {
				x: '100%',
				duration: Animations.timing.modals,
				ease: Animations.ease.circOut,
				onComplete: () => {
					this.goToSlide(0)
				}
			})
		}

		this.isActive = !this.isActive
	}

	// handleMouseMove(e) {
	// 	this.inertia.targetX = e.clientX
	// 	this.inertia.targetY = e.clientY

	// 	this.cursorPosition = Math.floor((e.clientX / window.innerWidth) * 2)

	// 	if(this.cursorPosition == 0) {

	// 		this.cursorCurrentPosition = this.cursorPosition

	// 		if(this.cursorCurrentPosition !== this.cursorPreviousPosition) {
	// 			this.cursorPreviousPosition = this.cursorCurrentPosition
				
	// 			this.cursor.classList.add('is-active')
	// 		}
			
	// 	} else if(this.cursorPosition == 1) {

	// 		this.cursorCurrentPosition = this.cursorPosition

	// 		if(this.cursorCurrentPosition !== this.cursorPreviousPosition) {
	// 			this.cursorPreviousPosition = this.cursorCurrentPosition

	// 			this.cursor.classList.remove('is-active')
	// 		}
	// 	}
	// }

	destroy() {
		window.emitter.off('modalGalleryInteracted', this.toggleVisibility)
		this.flkty.off('change', this.updateProgressBar)

		this.flkty.destroy()

		//GSAP.ticker.remove(this.update)

		// if(!window.isTouchscreen) {
		// 	this.element.removeEventListener('mousemove', this.handleMouseMove)
		// 	this.element.removeEventListener('mouseleave', this.hideCursor)
		// 	this.element.removeEventListener('mouseenter', this.showCursor)
		// }
	}

	update() {
	// 	this.inertia.currentX = GSAP.utils.interpolate(this.inertia.currentX, this.inertia.targetX, this.inertia.lerp)
	// 	this.inertia.currentY = GSAP.utils.interpolate(this.inertia.currentY, this.inertia.targetY, this.inertia.lerp)

	// 	this.cursor.style.transform = `translate3d(${this.inertia.currentX}px, ${this.inertia.currentY}px, 0)`
	}

	unmount() {

		this.destroy()
	}
}
