import GSAP from 'gsap'
import { SplitText } from 'gsap/SplitText'
// import { Animations } from '../helpers/Animations'

export default class PageTitle {
	constructor(el, index) {

		GSAP.registerPlugin(SplitText)

		this.element 		= el
		this.index 			= index

		this.title 			= this.element.querySelector('h1')
		this.show 			= this.show.bind(this)
		this.tlInstance 	= GSAP.timeline()
	}

	init() {

		this.setUp()

		window.emitter.on('pageReady', this.show)
	}

	setUp() {

		this.split = new SplitText(this.title, {
			type: 'words',
			wordsClass: "parent",
		})

		this.setIndexes(this.split.words)
	}

	setIndexes(elements) {

		elements.forEach((el, idx)=> {
			el.style.setProperty('--index', idx)
		})
	}

	show() {

		this.element.classList.add('in-view')

		// this.tlInstance.to(this.split.words, {
		// 	ease: Animations.ease.generic,
		// 	opacity: 1,
		// 	duration: Animations.timing.lgTitle,
		// 	yPercent: 0,
		// 	stagger: {
		// 		amount: Animations.stagger.lgTitle
		// 	}
		// })
	}

	unmount() {
		if(this.split) this.split.revert()
		if(this.tlInstance) this.tlInstance.kill()

		window.emitter.off('pageReady', this.show)
	}
}
