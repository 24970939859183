import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class SingleMedia {
	constructor(el, index) {

		this.element 		= el
		this.index 			= index

		this.imageWrapper	= this.element.querySelector('.intrinsic-image')
		this.image 			= this.element.querySelector('img')
	}

	init() {

		this.offsetDistance = this.image.offsetHeight - this.imageWrapper.offsetHeight

		GSAP.set(this.image, {
			y: -this.offsetDistance,
		})

		this.scrollTriggerInstance = GSAP.to(this.image, {
			y: () => 0,
			ease: 'none',
			scrollTrigger: {
				trigger: this.imageWrapper,
				start: 'top bottom',
				end: 'bottom top',
				scrub: true,
				invalidateOnRefresh: true
			}
		})
	}

	unmount() {
		if(this.scrollTriggerInstance) this.scrollTriggerInstance.kill()
	}
}
