import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class NavigationBar {
	constructor(el, index) {
		this.element 		= el
		this.index 			= index

		this.menuToggler 	= this.element.querySelector('.c-NavigationBar__toggler__btn')
		this.themeType 		= this.element.getAttribute('data-theme')
		this.handleClick 	= this.handleClick.bind(this)
		this.showNav 		= this.showNav.bind(this)
		this.onActive 		= this.onActive.bind(this)
	}

	init() {

		this.menuToggler.addEventListener('click', this.handleClick)

		window.emitter.on('pageReady', this.showNav)
		window.emitter.on('modalGalleryInteracted', this.onActive)

		if(this.themeType == 'light') {
			this.setupScrollTrigger()
		}
	}

	showNav() {

		GSAP.to(this.element, {
			y: 0,
			ease: Animations.ease.circOut,
			duration: Animations.timing.generic
		})
	}

	setupScrollTrigger() {

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top top',
			end: () => window.innerHeight - this.element.offsetHeight,
			onLeave: () => {
				this.onActive()
			},
			onEnterBack: () => {
				this.onInActive()
			}
		})
	}

	onActive() {
		this.element.classList.add('active')
	}

	onInActive() {
		this.element.classList.remove('active')
	}

	handleClick() {
		window.emitter.emit('menuCalled')
	}

	unmount() {
		if(this.STInstance) this.STInstance.kill()

		this.menuToggler.removeEventListener('click', this.handleClick)
		window.emitter.off('pageReady', this.showNav)
		window.emitter.off('modalGalleryInteracted', this.onActive)
	}
}